.jobalert-listitem {
    /*width: 100%;*/
    /*max-height: 25vh;*/
    margin: auto;
    padding-top: 0px;
    /*overflow-y: hidden;*/
}

.ImageContainer{
    max-width: 80%;
    min-width: 80%;
    max-height: 150px;
    min-height: 150px;
    border: 2px solid #942738;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
}

.left {
    width: 100%;
    height: 3.5em;
    font-family: Joanna MT;
    /*float: left;*/
    margin-top: 1em;
}
.right {
   /* margin-left: 3em; */
   /* height: 10em; */
}

.left-horizontal-rule {
    text-align: left;
    text-overflow: clip;
    line-height: 1;
    margin-left: 0em;
    border-bottom: 2px solid #942738;
    /* right: 60em; */
  /*  padding-left: 3em; */
}

.JobAlertButtons{
    width: 100%;
}

.left-event-name {
    text-align: left;
    text-overflow: clip;
    line-height: 1;
    margin-left: 0em;
    width: 100%;
   /* position: absolute; */
    /* right: 60em; */
  /*  padding-left: 3em; */
}

.application-button {
    margin-left: 2em;
}
.jobAlertImage {
    width:100%;
    height:150px;
    max-height:150px;
    object-fit:contain;
    padding-bottom:4px;
}

.MuiExpansionPanel-root:before{

    opacity: 0 !important;

}


