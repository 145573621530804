Dashboard {
    /*background: grey;*/
    /*position: absolute;*/
    /*top: 5vw;*/
    /*width: 80vw;*/
    /*height: 80vh;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
}

.padding-container {
    padding: 0em;
}

div .rw-navbar {
    /*overflow: hidden;*/
    /*position: fixed;*/
    bottom: 0;
    width: 100%;
    height: 6em;
    border-top: 2px solid black;
}

div .rw-navbar-button {
    display: inline-block;
    width: calc((100% / 5) - 2em);
    height: 5em;
    margin: 1em;
    text-align: center;
}

svg .navbar-button-image {
    width: calc((100% / 6) - 2em);
}