div .login-container {
    background: white;
    width: 100%;

    /*float: left;*/
    /*grid-template-columns: [content] 100%;*/
    /*grid-template-rows: [rw-logo] 50% [login-inputs] 50% ;*/
    /*align-items: center;*/
    /*height: 100%;*/

}

div .rw-logo {
    margin: 10em auto 0 auto;
    /*grid-column: content;*/
    /*grid-row: rw-logo;*/
    display: block;
    background-image:url( '../../img/icons/rw-user-jobs.svg') ;
    background-repeat: no-repeat;
    height: 12em;
    width: 12em;
}

div .rw-version-info {
    text-align: center;
    color: darkgray;
    display: block;
    margin: 0 auto 3em auto;

}

div .login-input {
    display: block;

}

/*div .login-input input{*/
/*    margin: 0 auto;*/
/*    display: block;*/
/*    text-align: center;*/
/*    font-size: 14px;*/
/*    font-family: Joanna MT;*/
/*    line-height: 24px;*/
/*    border-bottom: 2px solid #8C2332;*/
/*    border-bottom-style: solid;*/
/*    border-top-style: hidden;*/
/*    border-left-style: hidden;*/
/*    border-right-style: hidden;*/
/*}*/

/*div .login-input button{*/
/*    margin: 3em auto 0 auto;*/
/*    display: block;*/
/*    width: 10em;*/
/*    background: #B4233C;*/
/*    border-radius: 20px;*/
/*    font-family: Joanna MT;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 18px;*/
/*    line-height: 29px;*/
/*    text-align: center;*/
/*    color: white;*/

/*    border-style: hidden;*/
/*    border-color: buttonface;*/

/*}*/