.info > div{
   /*// display: inline-block;*/
   /*// width: 85%;*/
    max-width: 176px;
    min-width: 176px;
    margin: 0 auto;
}

#react-autowhatever-1 ul{
    padding: 0px;
margin: 0px;
}

.info div select{
    /*margin: 0 auto;*/
    /*display: block;*/
    /*text-align: center;*/
    /*font-size: 12pt;*/
    /*font-family: Joanna MT, Merriweather;*/
    /*line-height: 18px;*/
    /*border-bottom: 2px solid #942738;*/
    /*border-bottom-style: solid;*/
    /*border-top-style: hidden;*/
    /*border-left-style: hidden;*/
    /*border-right-style: hidden;*/
    /*background-color: #FFF;*/
    /*-webkit-appearance: none;*/
    /*border-radius: 0;*/

}

.info > svg {
    left: 140px;
    position: relative;
    top: -50px;
}



#react-autowhatever-1 ul li {
list-style: none;
    margin: 0;
    text-align: left;
    margin-bottom: 0.5em;

}

#react-autowhatever-1 ul li p{
    border-bottom: 1px solid #942738;
    margin:0px;

}

#react-autowhatever-1 ul li span{
    font-size: 8pt;
}
