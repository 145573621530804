.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.switch{

}

.reddington-theme-button {
  .bp3-button {
    background-color: #ffffff;
    border: 2px solid #b4233c;
    color: #b4233c;
    padding: 5px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 20px;
    font-style: normal;
    font-weight: normal;
    text-align: center;
  }

  .bp3-button:hover {
    background-color: #b4233c;
    border: 2px solid #b4233c;
    color: #ffffff;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bp3-control.bp3-switch input ~ .bp3-control-indicator {
  background: grey !important;
}

.bp3-control.bp3-switch input ~ .bp3-control-indicator :checked {
  background: red !important;
}

.bp3-control .bp3-control-indicator {
  font-size: 24px !important;
}