body {
  margin-top: 0px !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Merriweather", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div .login-container {
    background: white;
    width: 100%;

    /*float: left;*/
    /*grid-template-columns: [content] 100%;*/
    /*grid-template-rows: [rw-logo] 50% [login-inputs] 50% ;*/
    /*align-items: center;*/
    /*height: 100%;*/

}

div .rw-logo {
    margin: 10em auto 0 auto;
    /*grid-column: content;*/
    /*grid-row: rw-logo;*/
    display: block;
    background-image:url(/static/media/rw-user-jobs.a6370af3.svg);
    background-repeat: no-repeat;
    height: 12em;
    width: 12em;
}

div .rw-version-info {
    text-align: center;
    color: darkgray;
    display: block;
    margin: 0 auto 3em auto;

}

div .login-input {
    display: block;

}

/*div .login-input input{*/
/*    margin: 0 auto;*/
/*    display: block;*/
/*    text-align: center;*/
/*    font-size: 14px;*/
/*    font-family: Joanna MT;*/
/*    line-height: 24px;*/
/*    border-bottom: 2px solid #8C2332;*/
/*    border-bottom-style: solid;*/
/*    border-top-style: hidden;*/
/*    border-left-style: hidden;*/
/*    border-right-style: hidden;*/
/*}*/

/*div .login-input button{*/
/*    margin: 3em auto 0 auto;*/
/*    display: block;*/
/*    width: 10em;*/
/*    background: #B4233C;*/
/*    border-radius: 20px;*/
/*    font-family: Joanna MT;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 18px;*/
/*    line-height: 29px;*/
/*    text-align: center;*/
/*    color: white;*/

/*    border-style: hidden;*/
/*    border-color: buttonface;*/

/*}*/
.TextInput_input__VFy0X {
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 12pt;
    font-family: Joanna MT, Merriweather;
    line-height: 18px;
    border-bottom: 2px solid #942738;
    border-bottom-style: solid;
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    margin-bottom: 1.5em;
    background-color: #FFF;
    padding-left: 0.2em;
    padding-right: 0.2em;
    -webkit-appearance: none;
    border-radius:0;
}
.TextInput_input__VFy0X:focus {
    outline: none;
}


.TextInput_error__1t9g3 {
    text-align: center;
    font-family: Joanna MT;
    color: red;
}

@font-face {
    font-family: 'Joanna MT';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/JoannaMTItalic.1733d532.eot); /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'),
    url(/static/media/JoannaMTItalic.1733d532.eot?#iefix) format('embedded-opentype'), 
    url(/static/media/JoannaMTItalic.f158dc2d.woff2) format('woff2'), 
    url(/static/media/JoannaMTItalic.4b7d3b3c.woff) format('woff'), 
    url(/static/media/JoannaMTItalic.0b1d80ca.ttf) format('truetype'), 
    url(/static/media/JoannaMTItalic.fcaef0b7.svg#Joanna MT) format('svg'); /* Legacy iOS */
}

body {
    font-family: 'Joanna MT';
}
.NumberInput_input__sixhW {
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 16pt;
    font-family: Joanna MT;
    line-height: 18px;
    border-bottom: 2px solid #942738;
    border-bottom-style: solid;
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    margin-bottom: 1.5em;
    background-color: #FFF;
    padding-left: 0.2em;
    padding-right: 0.2em;
    -webkit-appearance: none;
    border-radius:0;
}
.NumberInput_input__sixhW:focus {
    outline: none;
}


.NumberInput_error__3TQiw {
    text-align: center;
    font-family: Joanna MT;
    color: red;
}

.info > div{
   /*// display: inline-block;*/
   /*// width: 85%;*/
    max-width: 176px;
    min-width: 176px;
    margin: 0 auto;
}

#react-autowhatever-1 ul{
    padding: 0px;
margin: 0px;
}

.info div select{
    /*margin: 0 auto;*/
    /*display: block;*/
    /*text-align: center;*/
    /*font-size: 12pt;*/
    /*font-family: Joanna MT, Merriweather;*/
    /*line-height: 18px;*/
    /*border-bottom: 2px solid #942738;*/
    /*border-bottom-style: solid;*/
    /*border-top-style: hidden;*/
    /*border-left-style: hidden;*/
    /*border-right-style: hidden;*/
    /*background-color: #FFF;*/
    /*-webkit-appearance: none;*/
    /*border-radius: 0;*/

}

.info > svg {
    left: 140px;
    position: relative;
    top: -50px;
}



#react-autowhatever-1 ul li {
list-style: none;
    margin: 0;
    text-align: left;
    margin-bottom: 0.5em;

}

#react-autowhatever-1 ul li p{
    border-bottom: 1px solid #942738;
    margin:0px;

}

#react-autowhatever-1 ul li span{
    font-size: 8pt;
}

.jobalert-listitem {
    /*width: 100%;*/
    /*max-height: 25vh;*/
    margin: auto;
    padding-top: 0px;
    /*overflow-y: hidden;*/
}

.ImageContainer{
    max-width: 80%;
    min-width: 80%;
    max-height: 150px;
    min-height: 150px;
    border: 2px solid #942738;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
}

.left {
    width: 100%;
    height: 3.5em;
    font-family: Joanna MT;
    /*float: left;*/
    margin-top: 1em;
}
.right {
   /* margin-left: 3em; */
   /* height: 10em; */
}

.left-horizontal-rule {
    text-align: left;
    text-overflow: clip;
    line-height: 1;
    margin-left: 0em;
    border-bottom: 2px solid #942738;
    /* right: 60em; */
  /*  padding-left: 3em; */
}

.JobAlertButtons{
    width: 100%;
}

.left-event-name {
    text-align: left;
    text-overflow: clip;
    line-height: 1;
    margin-left: 0em;
    width: 100%;
   /* position: absolute; */
    /* right: 60em; */
  /*  padding-left: 3em; */
}

.application-button {
    margin-left: 2em;
}
.jobAlertImage {
    width:100%;
    height:150px;
    max-height:150px;
    object-fit:contain;
    padding-bottom:4px;
}

.MuiExpansionPanel-root:before{

    opacity: 0 !important;

}



.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.reddington-theme-button .bp3-button {
  background-color: #ffffff;
  border: 2px solid #b4233c;
  color: #b4233c;
  padding: 5px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  text-align: center; }

.reddington-theme-button .bp3-button:hover {
  background-color: #b4233c;
  border: 2px solid #b4233c;
  color: #ffffff; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bp3-control.bp3-switch input ~ .bp3-control-indicator {
  background: grey !important; }

.bp3-control.bp3-switch input ~ .bp3-control-indicator :checked {
  background: red !important; }

.bp3-control .bp3-control-indicator {
  font-size: 24px !important; }

h2 {
    text-decoration: underline;
    -webkit-text-decoration-color: #b4233c;
            text-decoration-color: #b4233c;
}
Dashboard {
    /*background: grey;*/
    /*position: absolute;*/
    /*top: 5vw;*/
    /*width: 80vw;*/
    /*height: 80vh;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
}

.padding-container {
    padding: 0em;
}

div .rw-navbar {
    /*overflow: hidden;*/
    /*position: fixed;*/
    bottom: 0;
    width: 100%;
    height: 6em;
    border-top: 2px solid black;
}

div .rw-navbar-button {
    display: inline-block;
    width: calc((100% / 5) - 2em);
    height: 5em;
    margin: 1em;
    text-align: center;
}

svg .navbar-button-image {
    width: calc((100% / 6) - 2em);
}
div .loading-icon {
    margin: 10em auto 0 auto;
    /*grid-column: content;*/
    /*grid-row: rw-logo;*/
    display: block;
    background-image: url(/static/media/rw-user-jobs.a6370af3.svg);
    background-repeat: no-repeat;
    height: 12em;
    width: 12em;
}
