@font-face {
    font-family: 'Joanna MT';
    font-style: normal;
    font-weight: 400;
    src: url('JoannaMTItalic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'),
    url('JoannaMTItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('JoannaMTItalic.woff2') format('woff2'), /* Super Modern Browsers */
    url('JoannaMTItalic.woff') format('woff'), /* Modern Browsers */
    url('JoannaMTItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('JoannaMTItalic.svg#Joanna MT') format('svg'); /* Legacy iOS */
}

body {
    font-family: 'Joanna MT';
}