.input {
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 12pt;
    font-family: Joanna MT, Merriweather;
    line-height: 18px;
    border-bottom: 2px solid #942738;
    border-bottom-style: solid;
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    margin-bottom: 1.5em;
    background-color: #FFF;
    padding-left: 0.2em;
    padding-right: 0.2em;
    -webkit-appearance: none;
    -webkit-border-radius:0;
    border-radius:0;
}
.input:focus {
    outline: none;
}


.error {
    text-align: center;
    font-family: Joanna MT;
    color: red;
}
